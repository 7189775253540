import styles from "./index.module.scss";
import gf from "@assets/images/Home/gf.png";
import pa from "@assets/images/Home/pa.png";
import tx from "@assets/images/Home/tx.png";
import news from "@assets/images/Home/new.gif";

import { Animation } from "@components";

interface NewsProps {
  time: Date;
  desc: string;
  pic: string;
}

const NewsItem: React.FC<NewsProps> = ({ time, desc, pic }) => {
  const formartDate = (time: Date) => {
    const ny = new Date().getFullYear();
    const y = time.getFullYear();
    const m = time.getMonth();

    return `${ny === y ? "今" : y}年${m}月`;
  };

  const isCurrentYear = (time: Date) =>
    time.getFullYear() === new Date().getFullYear();

  return (
    <div className={styles.news_item}>
      <div className={styles.image}>
        <img src={pic} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.time}>
          {formartDate(time)}
          {isCurrentYear(time) && (
            <img className={styles.news} src={news} alt="" />
          )}
        </div>
        <div className={styles.desc} title={desc}>
          {desc}
        </div>
      </div>
    </div>
  );
};

const News = () => {
  const news: NewsProps[] = [
    {
      time: new Date(),
      desc: "众鑫与广发银行达成战略合作。",
      pic: gf,
    },
    {
      time: new Date("2021-03-01"),
      desc: "众鑫应平安科技业务需求，提供大量技术人员驻场研发。",
      pic: pa,
    },
    {
      time: new Date("2020-06-01"),
      desc: "众鑫被评选为腾讯云优质供应商。",
      pic: tx,
    },
  ];

  return (
    <div className={styles.container}>
      <Animation amount={0.8}>
        <h1 className={styles.title}>新闻动态</h1>
        <h2 className={styles.sub_title}>news information</h2>
      </Animation>
      <Animation
        isQueueAnim={true}
        amount={0.5}
        className={styles.news_container}
      >
        {news.map((n, i) => (
          <NewsItem {...n} key={i} />
        ))}
      </Animation>
    </div>
  );
};

export default News;
