import { arrayToClassName, isFunction } from "@utils";
import React, { useImperativeHandle, useState } from "react";
import styles from "./index.module.scss";
import defaultLogo from "@assets/images/Home/logo.png";

export interface NavigatorMenu {
  name: string;
  description: string;
  labels: string[];
  url: string;
  children: Partial<NavigatorMenu>[];
}

export interface NavigatorProps
  extends React.ClassAttributes<{ setCurrent: () => void }> {
  menus?: Array<Partial<NavigatorMenu>>;
  renderDrawer?: (data: Partial<NavigatorMenu>) => JSX.Element;
  logo?: string;
  closeShadow?: boolean;
  active: string[];
}

const Navigator = React.forwardRef((props: NavigatorProps, ref) => {
  const { menus, renderDrawer, closeShadow, logo, active } = props;
  const [current, setCurrent] = useState<Partial<NavigatorMenu> | null>(null);
  const handleEnter = (item: Partial<NavigatorMenu>) => {
    if (window._navigatorTimer) {
      clearTimeout(window._navigatorTimer);
      window._navigatorTimer = null;
    }
    setCurrent(item);
  };
  const hanldeLeave = () => {
    window._navigatorTimer = setTimeout(() => {
      setCurrent(null);
    }, 500);
  };

  useImperativeHandle(ref, () => ({
    setCurrent,
  }));

  return (
    <>
      <div
        className={styles.header}
        style={closeShadow ? { boxShadow: "none" } : {}}
      >
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={logo || defaultLogo} alt="" />
          </div>
          <ul className={styles.container}>
            {menus &&
              menus.map((item, index) => {
                return (
                  <li
                    className={arrayToClassName([
                      styles.navigation,
                      active.includes(item.name || "") ? styles.active : "",
                    ])}
                    onMouseEnter={() => handleEnter(item)}
                    onMouseLeave={hanldeLeave}
                    key={index}
                  >
                    <a href={item.url}>{item.name}</a>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      {isFunction(renderDrawer) &&
        current &&
        current.children &&
        current.children.length &&
        renderDrawer(current)}
    </>
  );
});

export default Navigator;
