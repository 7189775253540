import React from 'react'
import styles from './index.module.scss'


const Partners: React.FC = (props) => {
    const imgs = [...Array.from({length: 24,},(_, i) => i)].map(i => require(`@assets/images/Home/partners/partner${i + 1}.png`))
    
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>合作伙伴</h1>
            <h2 className={styles.sub_title}>Partners</h2>
            <div className={styles.content}>
                {
                    imgs.map(item => {
                        return <img src={item} alt="" key={item} />
                    })
                }
            </div>
        </div>
    )
}


export default Partners;