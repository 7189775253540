import { useRef } from "react";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Navigation, Autoplay } from "swiper";
import type { Swiper as SwiperClass } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/css/autoplay"
import styles from "./index.module.scss";
import { arrayToClassName } from "@utils";
import { SwiperContext, Animation } from "@components";

import rlzy from "@assets/images/Home/rlzy.png";
import yyyf from "@assets/images/Home/yyyf.png";
import xtyw from "@assets/images/Home/xtyw.png";
import sjbz from "@assets/images/Home/sjbz.png";

interface BusinessProps {
  title: string;
  desc: string;
  icon: string;
}

const BusinessItem: React.FC<BusinessProps> = ({ title, desc, icon }) => {
  return (
    <div className={styles.business_item}>
      <img src={icon} alt="" />
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
        <div className={styles.filter}></div>
        <div className={styles.modification}></div>
      </div>
    </div>
  );
};

const Business = () => {
  const swiperRef = useRef<SwiperClass>();

  const business: BusinessProps[] = [
    {
      title: "人力资源",
      desc: "为客户提供细致的业务咨询和人力招聘服务，协助客户快速组建团队和业务落地。",
      icon: rlzy,
    },
    {
      title: "应用研发",
      desc: "支持多元定制应用开发，提供端到端，整体或部分定制的流程化HRO或BPO服务。",
      icon: yyyf,
    },
    {
      title: "系统运维",
      desc: "打造一流运维团队，协助客户构建运维流水线，提供安全风控策略，支持驻场运维支撑。",
      icon: xtyw,
    },
    {
      title: "数据标注",
      desc: "提供金融，教育，运营商，政企等多行业互联网数据标注、采集、内容审核服务等。",
      icon: sjbz,
    },
  ];

  return (
    <section className={styles.business}>
      <Animation amount={0.8}>
        <h1 className={styles.title}>重点服务</h1>
        <h2 className={styles.sub_title}>Key services</h2>
      </Animation>
      <Animation amount={0.5}>
        <div className={styles.business_swiper}>
          <Swiper
            autoplay={{ delay: 2000 }}
            modules={[Navigation, Autoplay]}
            slidesPerView={4}
            spaceBetween={20}
            loop={true}
            className={styles.swiper_container}
          >
            {business.map((b) => (
              <SwiperSlide className={styles.swiper_slide} key={b.title}>
                <BusinessItem {...b} />
              </SwiperSlide>
            ))}
            <SwiperContext ref={swiperRef} />
          </Swiper>
          <div
            onClick={() => swiperRef.current?.slidePrev()}
            className={arrayToClassName([
              styles.swiper_btn,
              styles.swiper_btn_pre,
            ])}
          >
            <CaretLeftOutlined />
          </div>
          <div
            onClick={() => swiperRef.current?.slideNext()}
            className={arrayToClassName([
              styles.swiper_btn,
              styles.swiper_btn_next,
            ])}
          >
            <CaretRightOutlined />
          </div>
        </div>
      </Animation>
    </section>
  );
};

export default Business;
