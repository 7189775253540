import { arrayToClassName } from "@utils";
import React, { useRef } from "react";
import Navigator, { NavigatorMenu, NavigatorProps } from "../Navigator";
import styles from "./index.module.scss";

export interface ZocenetHeaderProps extends NavigatorProps {
  logo?: string;
  active: string[];
}

const menus: Partial<NavigatorMenu>[] = [
  {
    name: "首页",
    url: "/index.html",
  },
  {
    name: "业务范围",
    children: [
      {
        name: "人力资源",
        url: "/hrd.html",
      },
      {
        name: "应用研发",
        url: "/develop.html",
      },
      {
        name: "系统运维",
        url: "/operation.html",
      },
      {
        name: "数据标注",
        url: "/annotations.html",
      },
      {
        name: "内容审核",
        url: "/audit.html",
      },
      {
        name: "腾讯云",
        url: "/tencent.html",
      },
    ],
  },
  {
    name: "客户案例",
    url: "/case.html",
  },
  {
    name: "关于我们",
    description: "全方位的了解众鑫易乐，为今后合作奠定基础，时刻了解行业动态",
    children: [
      {
        name: "企业文化",
        url: "/culture.html",
      },
      {
        name: "企业发展",
        url: "/history.html",
      },
      // {
      //   name: "技术创新",
      //   url: "/innovate.html",
      // },
      {
        name: "团队管理",
        url: "/team.html",
      },
    ],
  },
];

export interface NavDrawerProps {
  menu: Partial<NavigatorMenu>;
  mouseEnter?: () => void;
  mouseLeave?: () => void;
  active: string[];
}

const NavDrawer: React.FC<NavDrawerProps> = (props) => {
  const { menu, mouseEnter, mouseLeave, active } = props;
  return (
    <div
      className={styles.drawer}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <div className={styles.drawer_content}>
        <div className={styles.drawer_left}>
          <p>{menu.name}</p>
          <div> {menu.description}</div>
          {/* <div className={styles.drawer_split_line}></div> */}
        </div>
        <div className={styles.drawer_right}>
          {menu.children &&
            menu.children.map((nav, i) => {
              return (
                <a
                  href={nav.url}
                  className={arrayToClassName([
                    styles.drawer_right_item,
                    active.includes(nav.name || "") ? styles.active : "",
                  ])}
                  key={i}
                >
                  <p>{nav.name}</p>
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const ZocenetHeader: React.FC<ZocenetHeaderProps> = (props) => {
  const { logo, closeShadow, active } = props;
  const navigatorRef = useRef<{
    setCurrent: (p: NavigatorMenu | null) => void;
  }>(null);

  const handleEnter = () => {
    window._navigatorTimer && clearTimeout(window._navigatorTimer);
  };
  const handleLeave = () => {
    navigatorRef.current && navigatorRef.current.setCurrent(null);
  };
  return (
    <div className={`${styles.container}`}>
      <div className={styles.wrapper}>
        <Navigator
          closeShadow={closeShadow}
          logo={logo}
          ref={navigatorRef}
          menus={menus}
          active={active}
          renderDrawer={(menu) => (
            <NavDrawer
              active={active}
              menu={menu}
              mouseEnter={handleEnter}
              mouseLeave={handleLeave}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ZocenetHeader;
