import React, { useRef } from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/antd.css";
import { Button } from "antd";
import { ZocenetFooter, ZocenetHeader, Animation, SwiperContext } from "@components";
import { Navigation, Autoplay } from "swiper";
import type { Swiper as SwiperClass } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/css/autoplay"
import reportWebVitals from "../reportWebVitals";
import styles from "./index.module.scss";
import Business from "./business";
import Profile from "./profile";
import Advantage from "./advantage";
import Partners from "./partners";
import News from "./news";
import Qualification from "./qualification";

function App() {
  const swiperRef = useRef<SwiperClass>();

  return (
    <div className={styles.home}>
      <ZocenetHeader active={["首页"]} />
      <Animation className={styles.home_banner} amount={0.5}>
        <div className={styles.business_swiper}>
          <Swiper
            autoplay={{ delay: 5000 }}
            speed={1000}
            modules={[Navigation, Autoplay]}
            slidesPerView={1}
            loop={true}
            className={styles.swiper_container_home}
          >
            <SwiperSlide className={styles.swiper_slide}>
              <div className={styles.banner}>
                <Animation className={styles.content}>
                  <h1 className={styles.main_title}>腾讯云官方授权代理商</h1>
                  <h2 className={styles.sub_title}>
                    关联我们、产品优惠购买、免费技术支持、迁移架构服务等
                  </h2>
                  <Button
                    type="primary"
                    onClick={() => window.open("./tencent.html")}
                    className={styles.banner_detail}
                  >
                    立即关联
                  </Button>
                </Animation>
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
              <div className={styles.banner1}>
                <Animation className={styles.content}>
                  <h1 className={styles.main_title}>专注于人力资源服务</h1>
                  <h2 className={styles.sub_title}>
                    联系我们、人力外包服务、项目外包服务、产品研发、审核标注等
                  </h2>
                  <Button
                    type="primary"
                    onClick={() => window.open("./develop.html")}
                    className={styles.banner_detail}
                  >
                    立即咨询
                  </Button>
                </Animation>
              </div>
            </SwiperSlide>
            <SwiperContext ref={swiperRef} />
          </Swiper>
        </div>
      </Animation>
      <Business />
      <Profile />
      <Advantage />
      <Partners />
      <News />
      <Qualification />
      <ZocenetFooter />
    </div>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals(console.log);
