import { ReactNode } from "react"
import { motion, Variants } from 'framer-motion'
import { isArray } from "@utils"


export interface AnimationProps {
    className?: string
    children?: ReactNode | ReactNode[]
    isQueueAnim?: boolean
    amount?: number
    once?: boolean
}

const Animation: React.FC<AnimationProps> = ({ children, className, isQueueAnim = false, once = true, amount = 0.8 }) => {
    const cardVariants: Variants = {
        offscreen: {
            y: 100,
            opacity: 0,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }
        }
    };

    const container = {
        offscreen: { opacity: 0 },
        onscreen: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                staggerDirection: 1
            }
        }
    }
    return <motion.div
        variants={isQueueAnim ? container : {}}
        className={className}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once, amount, }}>
            {
                isArray<ReactNode>(children) 
                ? children?.map((c,i) => <motion.div variants={cardVariants} key={i}>{ c }</motion.div>)
                : <motion.div variants={cardVariants}> { children } </motion.div>
            }
    </motion.div>
}

export default Animation