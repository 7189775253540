import { forwardRef, useImperativeHandle } from 'react';
import { useSwiper } from 'swiper/react';


const SwiperContext = forwardRef((props, ref) => {
    const swiper = useSwiper()
    useImperativeHandle(ref, () => swiper);
    return <></>
})

export default SwiperContext