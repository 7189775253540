import React from "react";

import styles from "./index.module.scss";
import menus from "@assets/json/footer.menu.json";
import wxgzh from "@assets/images/Footer/wxgzh.png";
import zxkfwx from "@assets/images/Footer/zxkfwx.png";

interface Link {
  name: string;
  url: string;
}

export interface LinkGroup {
  group: string;
  children: Link[];
}

export interface ZocenetFooterProps {
  links?: Array<LinkGroup>;
  className?: string
}

const ZocenetFooter: React.FC<ZocenetFooterProps> = (props) => {
  const { links, className } = props;
  const renderMenu: LinkGroup[] = links || (menus as LinkGroup[]);
  const classNames = [styles.container, className].join(' ')
  return (
    <div className={classNames}>
      <div className={styles.content}>
        <div className={styles.link_box}>
          {renderMenu.map((menu) => (
            <div className={styles.link_group} key={menu.group}>
              <div className={styles.link_title}>{menu.group}</div>
              <div className={styles.link_container}>
                {menu.children.map((child) => (
                  <a href={child.url} className={styles.link_item} key={child.name}>{child.name}</a>
                ))}
              </div>
            </div>
          ))}
          <div className={styles.qrcode_box}>
            <div className={styles.qrcode}>
              <img src={wxgzh} alt="微信公众号" />
              <p className={styles.qrcode_tip}>微信公众号</p>
            </div>
            <div className={styles.qrcode}>
              <img src={zxkfwx} alt="咨询客服微信号" />
              <p className={styles.qrcode_tip}>咨询客服微信号</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.copy}>
        Copyright ©{new Date().getFullYear()}众鑫易乐 <span style={{ cursor: 'pointer' }} onClick={() => window.open('https://beian.miit.gov.cn/')}>蜀ICP备2024057198号-1</span> 川公网安备 51010702000964号
      </div>
    </div>
  );
};

export default ZocenetFooter;
