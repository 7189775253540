import { ReactNode, useState } from 'react'
import styles from './index.module.scss'


interface Carousel3DProps {
    children: ReactNode[],
    z?: number
    perspective?: number
}


const Carousel3D:React.FC<Carousel3DProps> = (props) => {

    const { children, z = 400, perspective = 800 } = props
    const [current, setCurrent] = useState<number>(0)
    const angle = 360 / children.length;

    const genrateChildren = () => {
        return children.map((node, idx) => {
            const rotate = angle * current
            const r = angle * idx
            const transform = `rotateY(${r}deg) translateZ(${z / 1920 * 100}vw) rotateY(${-r + rotate}deg) scale(${(perspective - z)/perspective})`
            const style: React.CSSProperties = { transform: transform, transition: 'linear 0.4s'}
            const handlerClick = () => {
                // 点击的索引是否是当前查看的索引
                if (current % children.length === idx) return
                const t = (-r + rotate) % 360;
                // 根据目前卡片所旋转角度 判断点击的前一张或着后一张 正负两种情况都要考虑
                if ((t > 180 && t < 360) || (t < 0 && t > -180)) {
                    setCurrent(current + 1)
                } else {
                    setCurrent(current - 1)
                }
            }
            return <div onClick={handlerClick} className={styles.carousel_item} style={style} key={idx}>
                { node }
            </div>
        })
    }

    return <div className={styles.wrapper} style={{ perspective: `${perspective / 1920  * 100}vw`}}>
        <div className={styles.carousel} style={{ transform: `rotateY(${-(current * angle)}deg)`, transition: 'linear 0.4s' }}>
            { genrateChildren() }
        </div>
    </div>
}


export default Carousel3D