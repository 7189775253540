import profile from '@assets/images/Home/profile.png'
import styles from './index.module.scss'

import { motion, Variants } from 'framer-motion'

const Profile: React.FC = () => {

    const left: Variants = {
        hidden: {
            opacity: 0,
            x: -100
        },
        show: {
            opacity: 1,
            x: 0,
            transition: {
                type: 'spring',
                duration: 0.8
            }
        }
    }

    const right: Variants = {
        hidden: {
            opacity: 0,
            x: 100
        },
        show: {
            opacity: 1,
            x: 0,
            transition: {
                type: 'spring',
                duration: 0.8
            }
        }
    }

    return <div className={styles.container}>
        <motion.div initial="hidden" whileInView="show" viewport={{ once: true, amount: 0.8 }} className={styles.content}>
            <motion.div variants={left} className={styles.image}>
                <img src={profile} alt="" />
            </motion.div>
            <motion.div variants={right} className={styles.info}>
                <h1 className={styles.title}>公司简介</h1>
                <h2 className={styles.sub_title}>Company profile</h2>
                <p className={styles.description}>
                    公司专注于移动互联网和云业务时代的用户场景和需求，提供专业的产品和解决方案，主要业务分布于智能营销、智能安全、智慧教育、智慧政务以及公有云和私有云建设、研发和审核标注等业务领域。
                </p>
            </motion.div>
        </motion.div>
    </div>
}

export default Profile