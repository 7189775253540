import styles from './index.module.scss'
import { Carousel3D } from '@components'
import q1 from '@assets/images/Home/q1.png'
import q2 from '@assets/images/Home/q2.png'
import q3 from '@assets/images/Home/q3.png'
import q4 from '@assets/images/Home/q4.png'

const Qualification: React.FC = () => {
    return <section className={styles.container}>
        <div className={styles.title}>资质软著</div>
        <div className={styles.sub_title}>Soft work of qualification</div>
        <Carousel3D z={360} perspective={2600}>
            <div className={styles.qualification}>
                <img src={q1} alt="" />
                <h4>ISO 9001</h4>
            </div>
            <div className={styles.qualification}>
                <img src={q3} alt="" />
                <h4>ISO 27001</h4>
            </div>
            <div className={styles.qualification}>
                <img src={q4} alt="" />
                <h4>AI 标注</h4>
            </div>
            <div className={styles.qualification}>
                <img src={q2} alt="" />
                <h4>ISO 20000</h4>
            </div>
        </Carousel3D>
    </section>
}


export default Qualification