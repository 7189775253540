import styles from './index.module.scss'

import gxx from '@assets/images/Home/gxx.png'
import dwd from '@assets/images/Home/dwd.png'
import tdh from '@assets/images/Home/tdh.png'

import { Animation } from '@components'



interface AdvantageProps {
    title: string,
    desc: string[],
    pic: string
}

const AdvantageItem: React.FC<AdvantageProps> = ({ title, desc, pic }) => {
    return <div className={styles.advantage_item}>
        <div className={ styles.left }>
            <div className={styles.advantage_title}>{ title }</div>
            <ul className={styles.desc}>
                { desc.map(d => <li className={styles.desc_item} key={d}>{ d }</li>) }
            </ul>
        </div>
        <div className={ styles.right }>
            <img src={pic} alt="" />
        </div>
    </div>
}

const Advantage = () => {

    const advantages: AdvantageProps[] = [{
        title: '高效性人才招聘',
        desc: ['洞察客户需求', '明确合理目标', '实施优质方案'],
        pic: gxx
    },{
        title: '多维度技术服务',
        desc: ['分析应用设计', '对标最佳实践', '急速客户响应'],
        pic: dwd
    },{
        title: '整体化团队管理',
        desc: ['评估客户场景', '完善过程控制', '持续业务跟进'],
        pic: tdh
    }]

    return <section className={styles.container}>
        <Animation amount={0.2}>
            <h1 className={styles.title}>优势</h1>
            <h2 className={styles.sub_title}>advantage</h2>
        </Animation>
        <div className={styles.content}>
            <Animation isQueueAnim={true} className={styles.advantage}>
                { advantages.map(a => <AdvantageItem { ...a } key={a.title} />) }
            </Animation>
        </div>
    </section>
}


export default Advantage