// Icon.tsx
import React from 'react';


let importAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().forEach(requireContext);
try {importAll(require.context('@assets/images/svg', true, /\.svg$/));} catch (error) {console.log(error);}

interface IconProps {
    name: string,
    style?: React.CSSProperties
    className?: string
}

const Icon: React.FC<IconProps> = ({ name, style, className }) => {
    return (
        <svg className={className} style={style}>
            <use xlinkHref={`#${name}`}/>
        </svg>
    );
};
export default Icon;